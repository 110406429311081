.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageHeader h2 {
  color: var(---secondColor);
}

.pageHeader button {
  background-color: var(---thirdColor);
  color: #fff;
}

.pageHeader button:hover {
  color: var(---secondColor) !important;
  background-color: transparent;
  border-color: var(---thirdColor) !important;
}

.otherButtons {
  background-color: var(---primeColor);
  color: #fff;
}

.otherButtons.active {
  background-color: green;
}

.otherButtons.deactive {
  background-color: red;
}

.otherButtons.edit {
  background-color: orange;
}

.otherButtons:hover {
  border-color: var(---primeColor) !important;
  background-color: transparent;
  color: var(---primeColor) !important;
}
.otherButtons.deactive:hover {
  border-color: red !important;
  background-color: transparent;
  color: red !important;
}

.otherButtons.active:hover {
  border-color: green !important;
  background-color: transparent;
  color: green !important;
}

.otherButtons.edit:hover {
  border-color: orange !important;
  background-color: transparent;
  color: orange !important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-menu-dark
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-pr0fja).ant-menu-dark
  > .ant-menu
  .ant-menu-item-selected {
  background-color: var(---thirdColor) !important;
  border-radius: 5px;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-btn-primary {
  background-color: var(---primeColor) !important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-switch.ant-switch-checked,
:where(
    .css-dev-only-do-not-override-pr0fja
  ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: green !important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-switch,
:where(.css-dev-only-do-not-override-pr0fja).ant-switch:hover:not(
    .ant-switch-disabled
  ) {
  background: red !important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background-color: var(---secondColor) !important;
}
.ql-container.ql-snow {
  border: 1px solid #ccc;
  height: 200px;
}

.valueBooking {
  color: #808080 !important;
  display: flex;
  align-items: center;
}

.logoutBtn {
  width: 100%;
  background-color: var(---secondColor);
  color: #fff;
  border: 2px solid var(---secondColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoutBtn:hover {
  border: 2px solid var(---thirdColor) !important;
  color: var(---thirdColor) !important;
}

.loginSection {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #6bc8cd;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%2364c0c6' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%235db8be' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%2357afb5' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%2352a6ab' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%2351989D' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%234c8e93' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23478489' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23417b7e' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%233c7174' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%2337676A' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.loginSection form {
  width: 100%;
}
.loginBox {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255, 0.6);
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
}

:where(.css-pr0fja).ant-btn-primary,
.ant-radio-group-solid
  :where(
    .css-dev-only-do-not-override-pr0fja
  ).ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover,
.ant-radio-group-solid
  :where(
    .css-dev-only-do-not-override-pr0fja
  ).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
:where(.css-dev-only-do-not-override-pr0fja).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: var(---secondColor) !important;
  border-color: var(---secondColor) !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-radio-button-wrapper:hover {
  color: #111 !important;
}

.packageSelectStep {
  margin: 20px 0;
}

.checkStatus {
  background-color: var(---primeColor);
  color: #fff;
  display: flex;
  align-items: center;
  border: 2px solid var(---primeColor) !important;
}

.checkStatus:hover {
  background-color: transparent !important;
  border: 2px solid var(---primeColor) !important;
  color: var(---primeColor) !important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
:where(.css-dev-only-do-not-override-pr0fja).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot,
:where(.css-dev-only-do-not-override-pr0fja).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: green !important;
}

.quotation-sent {
  color: orange;
}

.follow-up {
  color: blue;
}

.confirm-meeting {
  color: green;
}

.confirm-meeting {
  color: green;
}

.not-intrested {
  color: red;
}

.active-client {
  text-transform: capitalize;
  color: green;
  font-weight: 500;
}

.removeButton {
  background-color: red;
  color: #fff;
}

.removeButton:hover {
  color: #fff !important;
}

.not-connected{
  color: orangered;
}