  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap');

:root{
    ---primeColor: #302043;
    ---secondColor: #37676A;
    ---thirdColor: #E98B15;
    ---primeFont:'Roboto', sans-serif;
}


*{
    padding: 0;
    margin: 0;
}

body{
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
    font-family: var(---primeFont);
}

